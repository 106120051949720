import Category from './category';
import Combo from './combo';
import ComboItem from './combo-item';
import Franchise from './franchise';
import Menu from './menu';
import Modifier from './modifier';
import ModifierGroup from './modifier-group';
import Order from './order';
import Product from './product';
import Profile from './profile';
import PromoCode from './promo-code';
import RecommendedProducts from './recommended-products';
import Reward from './reward';
import ScheduleExceptions from './schedule-exception';
import SmartPricing from './smart-pricing';
import SubscriptionProduct from './subscription-product';
import SubscriptionProductPrice from './subscription-product-price';
import Variant from './variant';
import Reservation from './reservation';

export default {
  Category,
  Combo,
  ComboItem,
  Franchise,
  Menu,
  Modifier,
  ModifierGroup,
  Order,
  Product,
  Profile,
  PromoCode,
  RecommendedProducts,
  Reward,
  ScheduleExceptions,
  SmartPricing,
  SubscriptionProduct,
  SubscriptionProductPrice,
  Variant,
  Reservation,
};
