import axios from 'axios';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { COOKIES } from '../services/exports/Constants';
import BackendApiClient from '../services/api/BackendApiClient';
import ModifiersManager from '../services/api/modifier/ModifiersManager';
import { InitialDataManager } from '../services/api/InitialDataManager';
import useSSO from './useSSO';
import useAuth from './useAuth';
import CategoriesManager from '../services/api/category/CategoriesManager';
import AccountsManager from '../services/api/account/AccountsManager';
import CombosManager from '../services/api/combo/CombosManager';
import StatsManager from '../services/api/stats/StatsManager';
import ComboItemsManager from '../services/api/combo-item/ComboItemsManager';
import CompaniesManager from '../services/api/company/CompaniesManager';
import DeliveryZonesManager from '../services/api/company/DeliveryZonesManager';
import GalleryManager from '../services/api/company/GalleryManager';
import MerchantProfileManager from '../services/api/company/MerchantProfileManager';
import PaymentOptionsManager from '../services/api/company/PaymentOptionsManager';
import CurrenciesManager from '../services/api/currency/CurrenciesManager';
import CustomersManager from '../services/api/customer/CustomersManager';
import FranchisesManager from '../services/api/franchise/FranchisesManager';
import GoogleReviewsManager from '../services/api/google-review/GoogleReviewsManager';
import LoyaltyAppAccountManager from '../services/api/loyalty-app-account/LoyaltyAppAccountManager';
import MenusManager from '../services/api/menu/MenusManager';
import MerchantsManager from '../services/api/merchant/MerchantsManager';
import MonthlyStatementsManager from '../services/api/monthly-statement/MonthlyStatementsManager';
import NotificationsManager from '../services/api/notification/NotificationsManager';
import OnboardingStepsManager from '../services/api/onboarding-step/OnboardingStepsManager';
import ModifierGroupsManager from '../services/api/modifier-group/ModifierGroupsManager';
import OrdersManager from '../services/api/order/OrdersManager';
import PayoutsManager from '../services/api/payout/PayoutsManager';
import PrintersManager from '../services/api/printer/PrintersManager';
import ProductsManager from '../services/api/product/ProductsManager';
import RecommendedProductsManager from '../services/api/product/RecommendedProductsManager';
import PromoCodesManager from '../services/api/promo-code/PromoCodesManager';
import PromoCodeComponentsManager from '../services/api/promo-code-component/PromoCodeComponentsManager';
import PromotionsManager from '../services/api/promotion/PromotionsManager';
import ReceiptsManager from '../services/api/receipt/ReceiptsManager';
import ReportsManager from '../services/api/report/ReportsManager';
import RewardsManager from '../services/api/reward/RewardsManager';
import ServiceContractsManager from '../services/api/service-contract/ServiceContractsManager';
import StaffAccountManager from '../services/api/staff-account/StaffAccountManager';
import StripeManager from '../services/api/stripe/StripeManager';
import SubscriptionsManager from '../services/api/subscription/SubscriptionsManager';
import SubscriptionProductsManager from '../services/api/subscription-product/SubscriptionProductsManager';
import SubscriptionProductPricesManager from '../services/api/subscription-product-price/SubscriptionProductPricesManager';
import VariantsManager from '../services/api/variant/VariantsManager';
import StorefrontManager from '../services/api/storefront/StorefrontManager';
import StorefrontDashboardManager from '../services/api/storefront/dashboard/StorefrontManager';
import CurrentCompanyManager from '../services/api/company/CurrentCompanyManager';
import useAppCookies from './useAppCookies';
import ServiceChargesManager from '../services/api/service-charge/ServiceChargesManager';
import FlyersManager from '../services/api/flyer/FlyersManager';
import SetupPackageFulfillmentsManager
  from '../services/api/setup-package-fulfillments/SetupPackageFulfillmentsManager';
import ReservationsManager from '../services/api/reservation/ReservationsManager';
import ReservationScheduleExceptionManager from '../services/api/reservation/ScheduleExceptionManager';
import ReservationAmendScheduleExceptionManager from '../services/api/reservation/AmendScheduleExceptionManager';
import CompanyScheduleExceptionManager from '../services/api/schedule-exception/ScheduleExceptionManager';
import CompanyAmendScheduleExceptionManager from '../services/api/schedule-exception/AmendScheduleExceptionManager';
import ServiceProvidersManager from '../services/api/service-provider/ServiceProvidersManager';
import CompanyReservationManager from '../services/api/reservation/CompanyReservationManager';
import DashboardReservationManager from '../services/api/reservation/DashboardReservationManager';
import RoomServiceLocationsManager from '../services/api/room-service-location/RoomServiceLocationsManager';
import DuplicatesManager from '../services/api/menu/DuplicatesManager';
import InternalInvoiceItemsManager from '../services/api/internal-invoice-item/InternalInvoiceItemsManager';
import SmartPricingRulesManager from '../services/api/smart-pricing-rules/SmartPricingRulesManager';
import TeamMembersManager from '../services/api/team/TeamMembersManager';
import TeamReportsManager from '../services/api/team/TeamReportsManager';
import MagicLinksManager from '../services/api/magic-link/MagicLinksManager';
import DeliveriesManager from '../services/api/delivery/DeliveriesManager';
import ReservationRuleManager from '../services/api/reservation/ReservationRuleManager';
import ReservationMediaManager from '../services/api/reservation/ReservationMediaManager';
import MarketingEmailsManager from '../services/api/email-marketing/MarketingEmailsManager';
import GoogleBusinessManager from '../services/api/google-business/GoogleBusinessManager';
import SeoManager from '../services/api/seo/SeoManager';

const RETRY_HEADER = 'x-axios-retry';

export default function useApiClient({ baseUrl = null, autoLogOut = true } = {}) {
  const { i18n } = useTranslation();
  const { getCookie } = useAppCookies([COOKIES.AUTH_TOKEN]);

  const token = getCookie(COOKIES.AUTH_TOKEN);

  const { company } = useSelector((state) => state.currentCompany);
  const { serviceProvider } = useSelector((state) => state.currentServiceProvider);

  const onBehalfOfCompany = company?.id ?? '';
  const onBehalfOfServiceProvider = serviceProvider?.id ?? '';

  const { logIn, logOut } = useAuth();
  const { refreshToken } = useSSO();

  const config = useMemo(
    () => ({
      baseURL: baseUrl ?? import.meta.env.VITE_API_BASE_URL,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'x-on-behalf-of-company': onBehalfOfCompany,
        'x-on-behalf-of-service-provider': onBehalfOfServiceProvider,
        'x-preferred-locale': i18n.language,
      },
    }),
    [baseUrl, token, onBehalfOfCompany, onBehalfOfServiceProvider, i18n],
  );

  const client = useMemo(() => {
    const instance = axios.create(config);
    instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const { config, response } = error;
        const { status } = response;
        const authHeader = config.headers.Authorization ?? 'Bearer null';
        const authToken = authHeader.split('Bearer ', 2)[1] ?? 'null';

        if (status !== 401) {
          return Promise.reject(error);
        }

        if (!authToken || authToken === 'null' || authToken === 'undefined') {
          logOut();

          return Promise.reject(error);
        }

        if (config.headers[RETRY_HEADER]) {
          autoLogOut && logOut();

          return Promise.reject(error);
        }

        return refreshToken()
          .then((response) => {
            const newToken = response.data.data.access_token;
            logIn(response.data);

            return instance.request({
              ...config,
              headers: {
                ...config.headers,
                Authorization: `Bearer ${newToken}`,
                [RETRY_HEADER]: 1,
              },
            });
          })
          .catch((error) => {
            const { status } = error.response;

            status === 401 && autoLogOut && logOut();
          });
      },
    );

    return instance;
  }, [config, autoLogOut]);

  const clientInstance = new BackendApiClient(client);

  return {
    config,
    client,
    clientInstance,
    AccountsManager: new AccountsManager(clientInstance),
    CategoriesManager: new CategoriesManager(clientInstance),
    CombosManager: new CombosManager(clientInstance),
    ComboItemsManager: new ComboItemsManager(clientInstance),
    CompaniesManager: new CompaniesManager(clientInstance),
    CurrentCompanyManager: new CurrentCompanyManager(clientInstance),
    DeliveriesManager: new DeliveriesManager(clientInstance),
    DeliveryZonesManager: new DeliveryZonesManager(clientInstance),
    GalleryManager: new GalleryManager(clientInstance),
    MerchantProfileManager: new MerchantProfileManager(clientInstance),
    PaymentOptionsManager: new PaymentOptionsManager(clientInstance),
    CurrenciesManager: new CurrenciesManager(clientInstance),
    CustomersManager: new CustomersManager(clientInstance),
    FlyersManager: new FlyersManager(clientInstance),
    FranchisesManager: new FranchisesManager(clientInstance),
    GoogleReviewsManager: new GoogleReviewsManager(clientInstance),
    GoogleBusinessManager: new GoogleBusinessManager(clientInstance),
    InternalInvoiceItemsManager: new InternalInvoiceItemsManager(clientInstance),
    LoyaltyAppAccountManager: new LoyaltyAppAccountManager(clientInstance),
    MagicLinksManager: new MagicLinksManager(clientInstance),
    DuplicatesManager: new DuplicatesManager(clientInstance),
    MenusManager: new MenusManager(clientInstance),
    MerchantsManager: new MerchantsManager(clientInstance),
    ModifiersManager: new ModifiersManager(clientInstance),
    ModifierGroupsManager: new ModifierGroupsManager(clientInstance),
    MonthlyStatementsManager: new MonthlyStatementsManager(clientInstance),
    NotificationsManager: new NotificationsManager(clientInstance),
    OnboardingStepsManager: new OnboardingStepsManager(clientInstance),
    OrdersManager: new OrdersManager(clientInstance),
    PayoutsManager: new PayoutsManager(clientInstance),
    PrintersManager: new PrintersManager(clientInstance),
    ProductsManager: new ProductsManager(clientInstance),
    RecommendedProductsManager: new RecommendedProductsManager(clientInstance),
    PromoCodesManager: new PromoCodesManager(clientInstance),
    PromoCodeComponentsManager: new PromoCodeComponentsManager(clientInstance),
    PromotionsManager: new PromotionsManager(clientInstance),
    ReceiptsManager: new ReceiptsManager(clientInstance),
    ReportsManager: new ReportsManager(clientInstance),
    ReservationsManager: new ReservationsManager(clientInstance),
    ReservationsRuleManager: new ReservationRuleManager(clientInstance),
    ReservationMediaManager: new ReservationMediaManager(clientInstance),
    CompanyReservationManager: new CompanyReservationManager(clientInstance),
    DashboardReservationManager: new DashboardReservationManager(clientInstance),
    ReservationScheduleExceptionManager: new ReservationScheduleExceptionManager(clientInstance),
    ReservationAmendScheduleExceptionManager: new ReservationAmendScheduleExceptionManager(clientInstance),
    CompanyScheduleExceptionManager: new CompanyScheduleExceptionManager(clientInstance),
    CompanyAmendScheduleExceptionManager: new CompanyAmendScheduleExceptionManager(clientInstance),
    SeoManager: new SeoManager(clientInstance),
    RewardsManager: new RewardsManager(clientInstance),
    RoomServiceLocationsManager: new RoomServiceLocationsManager(clientInstance),
    ServiceChargesManager: new ServiceChargesManager(clientInstance),
    ServiceContractsManager: new ServiceContractsManager(clientInstance),
    ServiceProvidersManager: new ServiceProvidersManager(clientInstance),
    SetupPackageFulfillmentsManager: new SetupPackageFulfillmentsManager(clientInstance),
    SmartPricingRulesManager: new SmartPricingRulesManager(clientInstance),
    StaffAccountManager: new StaffAccountManager(clientInstance),
    StatsManager: new StatsManager(clientInstance),
    StorefrontManager: new StorefrontManager(clientInstance),
    StorefrontDashboardManager: new StorefrontDashboardManager(clientInstance),
    StripeManager: new StripeManager(clientInstance),
    SubscriptionsManager: new SubscriptionsManager(clientInstance),
    SubscriptionProductsManager: new SubscriptionProductsManager(
      clientInstance,
    ),
    SubscriptionProductPricesManager: new SubscriptionProductPricesManager(
      clientInstance,
    ),
    TeamMembersManager: new TeamMembersManager(clientInstance),
    TeamReportsManager: new TeamReportsManager(clientInstance),
    VariantsManager: new VariantsManager(clientInstance),
    InitialDataManager: new InitialDataManager(clientInstance),

    MarketingEmailsManager: new MarketingEmailsManager(clientInstance),
  };
}
